/* eslint-disable react/prop-types */
import * as React from 'react';
import styled from 'styled-components/macro';
import { Typography } from 'components/DesignSystemV2/Typography';
import { fromScreen } from 'utils/media-query/responsive.util';
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  ZoomControl
} from 'react-leaflet';
import { useMounted } from 'utils/hooks/useMounted';
import { divIcon, Point } from 'leaflet';
import { renderToString } from 'react-dom/server';
import { colorsV2 } from 'style/colors-v2';
import cl from 'classnames';
import { Branch } from './components/Branch';
import { BranchIcon } from './components/BranchIcon';
import { BranchPopup } from './components/BranchPopup';

const Container = styled.div`
  margin-top: 32px;
  .intro {
    margin-top: 12px;
  }
  ${fromScreen(776)} {
    margin-top: 100px;
  }
`;

export const BranchLocationV3 = ({ title, desc, branchList }) => {
  const { locations } = branchList || {};
  const mounted = useMounted();

  return (
    <Container>
      <Typography
        variant="bold/24-32"
        tabletVariant="bold/32-40"
        desktopVariant="bold/40-48"
        v3
        as="h2"
      >
        {title}
      </Typography>
      <Typography
        variant="regular/16-24"
        desktopVariant="regular/20-28"
        v3
        className="intro"
      >
        {desc}
      </Typography>
      {mounted && <ListOffice branchList={locations} />}
    </Container>
  );
};

const Main = styled.div``;
const TabBar = styled.div`
  display: grid;
  grid-template-columns: max-content max-content max-content;
  gap: 8px;
  margin: 24px 0;
  padding: 12px 0;
  width: fit-content;
`;
const TabBarContent = styled.div`
  padding: 10px 12px;
  background: transparent;
  border: 1px solid ${colorsV2.neutral20};
  border-radius: 12px;
  cursor: pointer;
  &.isSelected {
    background: ${colorsV2.primary10};
    border: 1px solid ${colorsV2.primary10};
    > p {
    color: ${colorsV2.primary100};
  }
`;

const MainList = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 24px;
  .map-container {
    display: none;
    height: 478px;
    border-radius: 16px;
    z-index: 50;
  }

  .map-branch {
    width: 52px !important;
    height: 52px !important;
    img {
      width: 100% !important;
      height: 100% !important;
    }
  }
  .map-popup {
    bottom: 10px !important;
    left: -26px !important;

    .leaflet-popup-tip-container {
      display: none;
    }
    .leaflet-popup-content {
      max-width: 290px !important;
      margin: 0;
      padding: 16px;
      p {
        margin: 0;
      }
    }
  }
  ${fromScreen(776)} {
    .map-container {
      display: block;
    }
  }
  ${fromScreen(1144)} {
    grid-template-columns: repeat(2, 1fr);
    .map-container {
      height: 792px;
    }
  }
`;

const Branches = styled.div`
  height: fit-content;
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;
`;

const ListOffice = ({ branchList }) => {
  const hcmBranches = branchList?.filter(x => x.region === 'HCM');
  const hnBranches = branchList?.filter(x => x.region === 'HN');
  const dnBranches = branchList?.filter(x => x.region === 'DN');
  const regions = [
    {
      id: 'HCM',
      name: 'Hồ Chí Minh',
      branchs: hcmBranches,
      center: [10.769065379718926, 106.66787862087456]
    },
    {
      id: 'HN',
      name: 'Hà Nội',
      branchs: hnBranches,
      center: [21.022795101152287, 105.80060241227291]
    },
    {
      id: 'DN',
      name: 'Đà Nẵng',
      branchs: dnBranches,
      center: [16.060689157856533, 108.21926149440866]
    }
  ];

  const [regionSelected, setRegionSelected] = React.useState(regions[0]);
  const [selectedBranchId, setSelectedBranchId] = React.useState('');
  const handleChangeRegion = id => {
    const findRegion = regions?.find(x => x.id === id);
    if (findRegion) {
      setRegionSelected(findRegion);
    }
  };

  return (
    <Main>
      <TabBar>
        {regions?.map((eachRegion, idx) => (
          <TabBarContent
            key={idx}
            className={cl({ isSelected: regionSelected.id === eachRegion.id })}
            onClick={() => handleChangeRegion(eachRegion?.id)}
          >
            <Typography variant="medium/14-20" color={colorsV2.neutralPrimary}>
              {eachRegion?.name}
            </Typography>
          </TabBarContent>
        ))}
      </TabBar>
      <MainList>
        <MapContainer
          key={`${regionSelected?.center}`}
          center={regionSelected?.center}
          zoom={12}
          className="map-container"
          zoomControl={false}
        >
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          {regionSelected &&
            regionSelected?.branchs?.map((eachBranch, idx) => (
              <Marker
                key={idx}
                position={{ lat: eachBranch?.lat, lng: eachBranch?.lng }}
                icon={divIcon({
                  html: renderToString(
                    <BranchIcon
                      isActive={eachBranch.id === selectedBranchId}
                      image={eachBranch?.image}
                    />
                  ),
                  className: 'custom-icon',
                  iconSize: new Point(52, 52)
                })}
                eventHandlers={{
                  popupopen: () => {
                    setSelectedBranchId(eachBranch?.id);
                  },
                  popupclose: () => {
                    setSelectedBranchId('');
                  }
                }}
              >
                <Popup className="map-popup" closeButton={false}>
                  <BranchPopup
                    title={eachBranch?.title}
                    location={eachBranch?.location}
                    viewMapLink={eachBranch?.viewMapLink}
                  />
                </Popup>
              </Marker>
            ))}
          <ZoomControl position="bottomright" />
        </MapContainer>
        <Branches>
          {regionSelected &&
            regionSelected?.branchs?.map((eachBranch, idx) => (
              <Branch
                key={idx}
                title={eachBranch?.title}
                image={eachBranch?.image}
                location={eachBranch?.location}
                viewMapLink={eachBranch?.viewMapLink}
                linkContact={eachBranch?.linkContact}
              />
            ))}
        </Branches>
      </MainList>
    </Main>
  );
};
