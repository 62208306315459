import * as React from 'react';
import styled from 'styled-components/macro';
import { colorsV2 } from 'style/colors-v2';
import { Typography } from 'components/DesignSystemV2/Typography';
import { IconsFeaturePhone } from 'components/Icon/SVGIcons';
import { IconNavigationPin } from 'components/Icon/SVGIcons/IconNavigationPin';

const PopupWrap = styled.div`
  display: grid;
  gap: 16px;
`;

const PopupDescription = styled.div`
  display: grid;
  gap: 8px;
`;
const Icon = styled.div`
  display: grid;
  grid-template-columns: 20px 1fr;
  gap: 4px;
`;
const ViewMap = styled.div`
  display: grid;
  justify-content: end;
  text-decoration: underline;
  text-decoration-color: ${colorsV2.blue100};
  > a {
    cursor: pointer;
  }
`;

export const BranchPopup = ({ title, location, viewMapLink }) => {
  return (
    <PopupWrap>
      <Typography variant="semi-bold/14-20" color={colorsV2.black100}>
        {title}
      </Typography>
      <PopupDescription>
        <Icon>
          <IconNavigationPin width={20} height={20} color={colorsV2.black60} />
          <Typography variant="regular/14-20" color={colorsV2.black60}>
            {location}
          </Typography>
        </Icon>
        <Icon>
          <IconsFeaturePhone width={20} height={20} color={colorsV2.black60} />
          <Typography variant="regular/14-20" color={colorsV2.black60}>
            1800 96 96 39
          </Typography>
        </Icon>
        <ViewMap>
          <a href={viewMapLink} target="_blank" rel="noreferrer noopener">
            <Typography variant="regular/14-20" color={colorsV2.blue100}>
              View Map
            </Typography>
          </a>
        </ViewMap>
      </PopupDescription>
    </PopupWrap>
  );
};
