/* eslint-disable react/prop-types */
import * as React from 'react';
import styled from 'styled-components/macro';
import { IconsMapMini } from 'components/Icon/SVGIcons';
import { colorsV2 } from 'style/colors-v2';
import cl from 'classnames';

const MapBranch = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  border: 4px solid;
  border-color: white;
  border-radius: 14px;
  img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }

  &.isActive {
    border-color: ${colorsV2.primary100};
  }
`;

const DotWrap = styled.div`
  position: absolute;
  bottom: -20px;
  left: 10px;
  width: fit-content;
  height: fit-content;
`;

export const BranchIcon = ({ isActive = false, image = '' }) => {
  return (
    <MapBranch className={cl({ isActive })}>
      <img src={image} alt="map-brand" />
      <DotWrap>
        <IconsMapMini width={24} height={24} />
      </DotWrap>
    </MapBranch>
  );
};
